.login-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  
  

  .card {
    width: 400px;
    padding: 20px 20px 50px 20px;
  }

  .env-name-ctx {
    text-align: center;
    margin-top: -40px;
    margin-bottom: 40px;

    .env-name {
      background-image: linear-gradient(45deg, #dcdc75, #ffff00);
      padding: 2px 5px;
      color: #ff0000;
      font-weight: 500;
      border: 1px solid #ff0000;
      border-radius: 5px;
      font-size: 12px;
    }
  }

  .image-wrapper {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;    

    img {
      width: 200px;
    }
  }

  .header-wrapper {
    text-align: center;
    font-size: 20px;
    padding: 20px 0;
    color: whitesmoke;

    i {
      font-size: 80px;
    }
  }

  .content {
    border-top: none !important;
   
  }
  

  .button {
    margin-top: 25px;
    border-radius: 2px;
    background-color: #174174!important;
    border-radius: 5;
    border-style: solid;
    border-width: 1px;
    border-color: white !important;
    transition-duration: 0.4s;
    width: 100%;
    height: 40px;
    color:white;
    cursor: pointer;

  }
  
  
  .button:hover {
    background-color: #2163a0 !important;
    color: white;    
  }

  .ui.form .field > label {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 12px;
    color:white;
  }

  .message {
    font-size: 12px;
    text-align: center;
  }

  .forgot {
    margin-top: 10px;
    text-align: center;
    color:white;

    a {
      color: #d1e8f7;
      font-weight: bold;      
    }
  }
}
