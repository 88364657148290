.cs-schema-wrapper {
    .content-ctx {
        display: flex;
        height: calc(100vh - 133px);
    }

    .contents {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-width: 0;
    }

    .list-ctx {
        border-radius: 0;
        margin-bottom: 0;
        overflow: auto;

        .item {
            padding: 20px;
        }
    }
}
