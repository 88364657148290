body {
    font-family: "Source Sans Pro", sans-serif;
}

.app-ctx {
    height: 100vh;
    padding-top: 56px;
    overflow: hidden;
}

.section-title,
.section-header,
.section-subheader {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .label {
        flex: 1;
        color: #3d4d65;
    }

    .actions {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: end;

        .button:not(.icon) {
            min-width: 100px;
        }
    }
}

.section-title {
    padding: 20px;
    border-bottom: 3px solid #3d4d65;

    .label {
        font-size: 30px;
    }
}

.section-header {
    margin: 20px 0;

    .label {
        font-size: 28px;
    }
}

.section-subheader {
    margin: 20px 0 10px 0;

    .label {
        font-size: 16px;
        font-weight: bold;
        text-transform: capitalize;
    }
}

.buttons-ctx {
    text-align: center;

    .button {
        min-width: 100px;
    }
}

.table.sortable th .icon {
    float: right;
}

.pusher {
    &.dimmed {
        max-height: 100%;
    }
}

.sidebar > * {
    padding: 10px;
    background: #ffffff;
    min-height: 100%;
}

.ui.toggle.checkbox.red input:checked ~ label::before {
    background-color: rgb(208, 25, 25) !important;
}
