.manage-apis-wrapper {
    .content-ctx {
        display: flex;
        height: calc(100vh - 133px);
    }

    .list-ctx {
        width: 200px;
        border-right: 1px solid #dededf;
        overflow: auto;
    }

    .list-item {
        border-bottom: 1px solid #dededf;
        padding: 20px;
        font-size: 12px;
        cursor: pointer;

        &.selected {
            background-color: #efefef;
        }

        &:hover {
            background-color: #f5f5f5;
        }

        .name {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
        }
    }

    .frame-ctx {
        flex: 1;
        display: flex;

        iframe {
            border: none;
            width: 100%;
            height: calc(100vh - 165px);
        }
    }
}
