.ui.cs-header-wrapper {
  height: 56px;
  background-color: #174174;
  
  .logo-wrapper {
    position: relative;

    img {
      width: 125px !important;
    }
  }

  .site-title {
    color: #fff;
    font-size: 25px;
    &:hover {
      color: #fff !important;
    }
  }

  .env-name {
    background-image: linear-gradient(45deg, #dcdc75, #ffff00);
    padding: 2px 5px;
    color: #ff0000;
    font-weight: 500;
    border-radius: 5px;
    font-size: 10px;
    position: absolute;
    top: 3px;
    right: 3px;
  }
}

.cs-menu-dropdown {
    color: #fff !important; 
    flex-direction: row-reverse;
    i {
        font-size: 1.5em;
    }
    .icon {
      font-size: 1.1em !important; 
    }
}

