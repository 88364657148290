.knowledge-center-wrapper {
    height: calc(100vh - 80px);
    overflow: hidden;

    .ui.buttons .or {
        z-index: 0;
    }

    .scroll-ctx {
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
    }

    .section-title {
        margin: 20px;
    }

    .grid-ctx {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;
        height: calc(100vh - 180px);

        .thumbnail {
            .ribbon {
                top: 15px;
                left: -14px;
                position: absolute;
            }
        }
    }

    .record-form {
        width: 450px;
    }

    .drop-zone {
        height: 125px;
        border: 1px dashed #dededf;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }

    .list-ctx {
        height: calc(100vh - 180px);

        .video-ctx {
            display: flex;
            text-decoration: none;
            width: calc(100% - 30px);
        }

        .details {
            flex: 1;
            padding: 0 10px;
            color: #3d4d65;
        }

        .thumbnail {
            width: 300px;
        }

        .title {
            font-size: 25px;
            margin-bottom: 10px;
        }

        .module {
            font-size: 10px;
            font-weight: 500;
            margin-bottom: 5px;
        }
    }

    .thumbnail {
        position: relative;
        margin-bottom: 10px;

        .thumbnail-button {
            position: absolute;
            display: none;
            top: 0;
            right: 0;

            button {
                background-color: #ffffff;
                border: none;
                margin: 2px;
                height: 30px;
                width: 30px;
                cursor: pointer;
            }

            i.icon {
                margin: 0 0 0 0;
            }
        }

        &:hover {
            .thumbnail-button {
                display: block;
            }
        }

        img {
            width: 100%;
        }
    }

    .grid-ctx {
        .thumbnail {
            img {
                height: 225px;
            }
        }
    }

    .list-ctx {
        .thumbnail {
            img {
                height: 200px;
            }
        }
    }

    .video-ctx {
        width: 337.5px;
        margin: 15px;
    }

    .empty-message {
        text-align: center;
        margin-top: 40px;
    }

    .browse-link {
        cursor: pointer;
    }

    .module-filter {
        margin-right: 10px;
        min-width: 250px !important;
    }
}
