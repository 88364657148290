.cs-filter-ctx {
    margin-bottom: 40px;

    .associations {
        margin-top: 10px;

        .item {
            background: #f5f5f5;
            padding: 10px;
            border: 1px solid #dededf;
            align-items: center;

            i {
                margin: 0 5px;
                cursor: pointer;
            }

            .header {
                display: flex;
                font-size: 16px;
                font-weight: 300 !important;
            }

            .label {
                flex: 1;
            }

            .description {
                color: rgba(0, 0, 0, 0.4);
            }

            .actions {
                display: flex;

                i {
                    font-size: 20px;
                    cursor: pointer;
                }
            }

            .additional-properties {
                margin-top: 20px;

                .field {
                    display: flex;
                    align-items: baseline;

                    .react-datepicker-wrapper {
                        flex: 1;
                    }

                    label {
                        padding-left: 8px;
                        flex: 1;
                    }
                }
            }
        }
    }
}
