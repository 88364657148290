.cs-entity-ctx {
    padding: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .table-ctx {
        overflow: auto;

        i {
            margin-right: 5px;
            cursor: pointer;
        }

        table {
            .column-checkbox {
                width: 40px;
            }
            .column-actions {
                width: 90px;
                white-space: nowrap;
            }
        }
    }

    .filter-ctx {
        margin-right: 10px;

        .dropdown {
            width: 150px;
        }
    }

    .pagination-ctx {
        padding: 5px 0;
        text-align: center;
    }

    .actions {
        .dropdown {
            .menu {
                min-width: 120px;

                .left.dropdown.icon {
                    margin-right: 1em;
                }
            }
        }
    }
}
