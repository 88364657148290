.cs-entity-access {
    .react-datepicker-wrapper {
        flex: 1;
    }

    .list-ctx {
        .item {
            background: #f5f5f5;
            font-size: 16px !important;
            font-weight: 300 !important;
            padding: 10px;
            border: 1px solid #dededf;
        }
    }
}
