.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; 
}

.section-title {
  margin: 20px 0;
  font-size: 1.5em; 
}

.ui.vertical.menu {
  width: 300px;
  border: none;
  box-shadow: none;
}

.ui.vertical.menu .item {
    text-align: justify;
    font-size: 20px;
    white-space: nowrap;
    i {
      float: left !important;
      margin-right: 12px !important;
    }
    .icon {
      font-size: 1.1em !important; 
    }
}


