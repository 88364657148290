.cs-acl-settings-wrapper {
    .content-ctx {
        display: flex;
        height: calc(100vh - 133px);
    }

    .contents {
        padding: 20px;
    }
}
