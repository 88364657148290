.react-datepicker-time__caption {
    vertical-align: middle;
    height: 1em;
}

.react-datepicker-popper {
    z-index: 2;
}

.react-datepicker__close-icon {
    padding-right: 0.7em;
    &::after {
        background: none;
        color: #000;
        opacity: 0.7;
        padding: 0;
        content: "\f00d";
        font-family: Dropdown;
    }
}
